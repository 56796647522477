<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10 text-left"
        >
          <div
            class="font-c8 text-c22"
            v-if="!securise"
          >
            SECURISEZ VOTRE COMPTE
          </div>

          <div
            class="text-83 text-c14 mb-2 mt-4"
            v-if="!securise"
          >
            Vous utilisez un mot de passe par défaut. Veuillez le changer pour sécuriser votre compte.
          </div>


          <div
            class="text-center text-c18 font-c4 mt-6"
            v-if="securise"
          >
            <div class="rounded-8  h-133 flex items-center">
              <div class="w-full">
                <icon
                  :data="icons.success"
                  height="50"
                  width="50"
                  original
                  color="green"
                />
                <div class="font-c8 text-c22 mt-3">
                  COMPTE SÉCURISÉ
                </div>
              </div>
            </div>

            <div class="w-full mt-6">
              <buton
                label="OK"
                size="16px"
                weight="600"
                height="51px"
                radius="5px"
                background="#31B049"
                @info="success"
              />
            </div>
          </div>

          <div v-if="!securise">
            <div
              class="text-c18 font-c4 mt-6 text-left"
            >
              <div class="text-83 text-c14 mb-2 mt-4">
                Nouveau mot de passe
              </div>
              <password
                height="46px"
                radius="8px"
                @info="backPassword"
              />

              <div class="text-orange text-c10 mb-2 mt-2 pl-2">
                Le mot de passe doit comporter au moins une lettre majuscule, un chiffre, des lettres minuscules et un signe.
              </div>

              <div class="text-83 text-c14 mt-4 mb-2">
                Confirmer mot de passe
              </div>
              <password
                height="46px"
                radius="8px"
                @info="backConfirmation"
              />
            </div>

            <div
              v-if="error !== null"
              class="text-red text-center text-c14 mt-6"
            >
              {{ error }}
            </div>
            <div class="mt-12 flex justify-center">
              <div class="w-full">
                <buton
                  :charge="charge"
                  label="Enregistrer"
                  size="16px"
                  weight="600"
                  height="51px"
                  radius="5px"
                  background="#312879"
                  @info="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import success from '../../assets/icons/lock.svg'
import password from '../helper/form/password'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: 'Success',
  components: { PopupBase, buton, password },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    flow: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        success,
      },
      error: null,
      response: {},
      charge: false,
      securise: false
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    backPassword (a) {
      this.response.password = a
    },

    backConfirmation (a) {
      this.response.confirmation = a
    },

    success () {
      this.$store.dispatch('saveToken', this.flow.token)
      this.$store.dispatch('saveUser', this.flow)

      if (this.flow.user.userType === 'SFDCOMMERCIAL') {
        window.location.replace(window.location.origin + '#/coupons')
      } else {
        window.location.replace(window.location.origin + '#/dashboard')
      }
    },

    save () {
      // console.log(this.response)
      if (this.response.password) {
        if (this.response.password === this.response.confirmation) {
          this.savePass()
        } else {
          this.error = 'Mots de passe non conforme'
        }
      } else {
        this.error = 'Veuillez remplir les champs et ressayer'
      }
    },

    savePass () {
      this.error = null
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.changePass, {
        password: this.response.password
      })
          .then(response => {
            // console.log(response)
            this.charge = false
            this.securise = true
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
      z-index: 999;
    }
    .agence {
      background: #EFF3FE;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8.03241px;
      height: 79.52px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
      z-index: 999;
    }

    .tete {
      background-color: #F3F7FA;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
