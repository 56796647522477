<template>
  <changePass
    :activation="activePass"
    v-if="activePass"
    :flow="successData"
  />

  <div class="w-full h-full all">
    <div class="flex items-center justify-center">
      <div class="diviser h-1 bg-e0 w-1/3" />
      <div class="ml-4 font-c8 w-1/3 text-c24">
        Connexion
      </div>
      <div class="diviser h-1 bg-e0 w-1/3 ml-4" />
    </div>

    <div class="text-c16 text-83 mt-3">
      Veuillez entrer vos identifiants pour continuer
    </div>

    <div class="text-left text-c14 text-83 font-c4 mt-10">
      Email
    </div>

    <div class="text-left mt-4">
      <div class="inputo h-46 flex pl-4 items-center rounded-10">
        <input
          v-model="identifiant"
          type="text"
          placeholder=""
          class="h-full w-full text-c18"
        >
      </div>
    </div>

    <div class="flex mt-6">
      <div class="text-left text-c14 text-83 font-c4 w-2/3">
        Mot de passe
      </div>
      <div class="text-left text-c18 font-c4 w-1/3 text-right">
        <span
          v-if="typePassword === 'password'"
          class="cursor-pointer text-c14"
          @click="afficher"
        >
          Afficher
        </span>
        <span
          v-if="typePassword === 'text'"
          class="cursor-pointer"
          @click="cacher"
        >
          Cacher
        </span>
      </div>
    </div>


    <div class="text-left mt-4">
      <div class="inputo h-60 flex pl-4 items-center rounded-10">
        <input
          v-model="password"
          :type="typePassword"
          placeholder=". . . ."
          class="h-full w-full text-c18"
        >
      </div>
    </div>

    <div
      v-if="error !== null"
      class="mt-4 text-red"
    >
      {{ error }}
    </div>

    <div class="mt-4">
      <div class="text-oho-blue text-c14 font-c4 underline cursor-pointer">
        Mot de passe oublié ?
      </div>
    </div>

    <div
      class="mt-6 h-51 mb-10 bg-green-second flex justify-center items-center text-c18 text-white rounded-10 cursor-pointer"
      @click="goNext"
    >
      <easy-spinner
        v-if="charge"
        type="dots"
        size="30"
      />
      <div v-if="!charge">
        Se connecter
      </div>
    </div>
  </div>
</template>

<script>
import apiroutes from '@/router/api-routes'
import http from '@/plugins/https'
import changePass from '../../popup/changePass'

export default {
  name: "Index",
  components: {
    changePass
  },
  data () {
    return {
      charge: false,
      identifiant: '',
      password: '',
      error: null,
      typePassword: 'password',
      activePass: false,
      successData: null
    }
  },

  methods: {

    afficher(){
      this.typePassword = 'text'
    },

    cacher(){
      this.typePassword = 'password'
    },

    goNext(){
      this.error = null
      if (this.identifiant.length > 0 && this.password.length > 0){
        this.charge = true
        this.login()
      } else {
        this.error = 'Vérifiez vos identifiants et ressayez'
      }
    },

    login(){
      http.post(apiroutes.baseURL + apiroutes.login, {
        username: this.identifiant,
        password: this.password
      })
          .then(response => {
            this.charge = false
            console.log(response)

            // this.$router.push('/dashboard')
            if (response.user.isFirstConnect || response.user.isFirstConnect === null) {
              this.successData = response
              this.activePass = true
            } else{
              this.$store.dispatch('saveToken', response.token)
              this.$store.dispatch('saveUser', response)
              if (response.user.userType === 'SFDCOMMERCIAL') {
                window.location.replace(window.location.origin + '#/coupons')
              } else {
                window.location.replace(window.location.origin + '#/dashboard')
              }
            }

          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.data.statusCode === 401){
              this.error = 'Identifiants incorrectes'
            }
          })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

.inputo{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  height: 46px;
}
.inputo:focus-within {
  border-color: $focus-color;
}
input{
  background-color: transparent;
}
</style>
