<template>
  <div class="big-container flex justify-center items-center">
    <div class="flex w-1/3 justify-center">
      <div class="partie2 w-full pr-14 pl-14">
        <div class="flex justify-center">
          <!--          <icon-->
          <!--              :data="icons.logo"-->
          <!--              height="100"-->
          <!--              width="100"-->
          <!--              class=""-->
          <!--              @click="$emit('oga', false)"-->
          <!--              original-->
          <!--          />-->
          <img
            src="../../assets/images/png/logo.png"
            class="h-20 mt-10"
            alt=""
          >
        </div>

        <div
          class="mt-2"
        >
          <identifiant
            v-if="position === 1"
          />
        </div>

        <div
          v-if="position === 3"
          class="mt-12"
        >
          <change-pass
            :donnes="user"
            :token="token"
            @cTrain="retourIdent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import identifiant from '@/component/cdevs/sign/identifiant'
import changePass from '@/component/cdevs/sign/changePass'


export default {
  name: 'Login',
  components: {
    identifiant,
    changePass
  },
  data () {
    return {
      position: 1,
      user: null,
      token: null,
      // icons: {
      //   logo
      // }
    }
  },
  methods: {
    voir () {
      if (this.password === 'password') {
        this.password = 'text'
      } else {
        this.password = 'password'
      }
    },

    save () {
      this.charge = true
      this.error = null
    }
  }
}

</script>

<style lang="scss" scoped>
    @import "../../assets/styles/sass/variables";
    .big-container{
      height: 100vh;
      width: 100%;
      background-color: #F3F5F8;
    }

    .aa{
      background-color: #3E3D53;
    }

    .partie2{
      background-color: white;
      box-shadow: 0px 110.121px 88.0972px rgba(0, 0, 0, 0.03), 0px 71.375px 51.5939px rgba(0, 0, 0, 0.0227778), 0px 42.4172px 28.0606px rgba(0, 0, 0, 0.0182222), 0px 22.0243px 14.3158px rgba(0, 0, 0, 0.015), 0px 8.97286px 7.17829px rgba(0, 0, 0, 0.0117778), 0px 2.03929px 3.46679px rgba(0, 0, 0, 0.00722222);
      border-radius: 12px;
    }
    @media screen and (max-width: 1400px) and (min-width: 800px){
    }
</style>
